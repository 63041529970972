import './config/polyfills'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import withStyles from 'react-jss'
import styles from './styles/base.styles'
import 'normalize.css'

const App = React.lazy(() => import('./pages/App'))

const StyledApp = withStyles(styles)(App)

const AppLoader = () => {
  return (
    <Suspense fallback={<div />}>
      <StyledApp />
    </Suspense>
  )
}

ReactDOM.render(<AppLoader />, document.getElementById('root'))
