import base from '@paymark/commons-ui/build/styles/base.styles'
import { fontLoader, colors } from '@paymark/commons-ui'
import regular from '@paymark/commons-ui/build/assets/fonts/proximanova-regular-webfont.woff'
import regular2 from '@paymark/commons-ui/build/assets/fonts/proximanova-regular-webfont.woff'
import semibold from '@paymark/commons-ui/build/assets/fonts/proximanova-semibold-webfont.woff'
import semibold2 from '@paymark/commons-ui/build/assets/fonts/proximanova-semibold-webfont.woff'
import bold from '@paymark/commons-ui/build/assets/fonts/proximanova-bold-webfont.woff'
import bold2 from '@paymark/commons-ui/build/assets/fonts/proximanova-bold-webfont.woff'

const fontPaths = {
  regular: {
    woff: regular,
    woff2: regular2,
  },
  semibold: {
    woff: semibold,
    woff2: semibold2,
  },
  bold: {
    woff: bold,
    woff2: bold2,
  },
}

const styles = {
  ...base,
  '@global': {
    body: {
      position: 'relative',
      backgroundColor: colors.background3,
    },
    ...base['@global'],
  },
  '@font-face': fontLoader.getFonts(fontPaths),
}

export default styles
